export default (initialSeconds = 5) => ({
    /** @type {Number} seconds */
    seconds: initialSeconds,
    /** @type {Number} intervalId */
    intervalId: 0,

    /**
     * Initialize the component
     */
    init() {
        this.restartCounter(initialSeconds);
    },

    /**
     * Update the second display
     */
    updateTime(self) {
        self.seconds -= 1;
        if (0 === self.seconds) {
            self.removeTimer();
        }
    },

    /**
     * Restart the counter
     * @param {Number} restartWithSeconds The initial seconds time
     */
    restartCounter(restartWithSeconds) {
        this.removeTimer();
        this.seconds = restartWithSeconds;
        this.intervalId = setInterval(() => { this.updateTime(this); }, 1000);
    },

    /**
     * Remove a runned timer
     */
    removeTimer() {
        clearInterval(this.intervalId);
        this.intervalId = 0;
    },
});
