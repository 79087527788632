import zenscroll from 'zenscroll';

export default () => {
    return elementId => {
        const element = document.getElementById(elementId);
        if (undefined === element) {
            throw `The given element id ${elementId} does not exists.`;
        }
        zenscroll.to(elementId);
    };
};
