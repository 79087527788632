import Alpine from "alpinejs";
import { DisplayState } from "../../enums";
import configuration from "../../config/app.json";
import { BannerStatus } from "../../enums";
import Numerify from "../../services/Numerify";
import zenScroll from "zenscroll";

const lineRemarkMaxInput = configuration.estimate_edition.lines_remark_max_input;

/**
 * Important note:
 * Methods with "call" prefix call the livewire component (backend) then update frontend.
 * Methods without this previx update only the frontend.
 *
 * @typedef {import('../../interfaces/ReaderRequests').LineDto} LineDto
 * @typedef {import('../../interfaces/ReaderRequests').LineRemark} LineRemark
 * @typedef {import('../../interfaces/ReaderRequests').TotalPanelItem} TotalPanelItem
 * @typedef {import('../../interfaces/ReaderRequests').ErrorBag} ErrorBag
 * @typedef {import('../../interfaces/ReaderRequests').PriceDisplay} PriceDisplay
 * @typedef {import('../../interfaces/ReaderRequests').EstimatePermissions} EstimatePermissions
 * @typedef {import('../../interfaces/ReaderRequests').LotColors} LotColors
 * @typedef {import('../../interfaces/ReaderRequests').InitialRequestData} InitialRequestData
 * @typedef {import('../../interfaces/ReaderRequests').LineCollection} LineCollection
 * @typedef {import('../../interfaces/ReaderRequests').LineRequest} LineRequest
 * @typedef {import('../../interfaces/ReaderRequests').LineErrorItem} LineErrorItem
 *
 * @param {Number} initialEstimateVersion
 * @param {Number} initialLineCount
 * @returns {Object}
 */
export default (initialEstimateVersion, initialLineCount, lesserValueUnit) => ({
    // Main data
    /** @type {any} estimateVersion */
    estimate: { created_at: "" },
    /** @type {Number} estimateVersion */
    estimateVersion: initialEstimateVersion,
    /** @type {Number} lineCount */
    lineCount: initialLineCount,
    /** @type {Array<LineDto>} lines */
    lines: [],
    /** @type {Array<String>} estimateRemarks */
    estimateRemarks: [],
    /** @type {Array<LineRemark>} lineRemarks */
    lineRemarks: [],
    /** @type {Array<TotalPanelItem>} totalPanel */
    totalPanel: [],
    /** @type {ErrorBag} lineErrors */
    errorBag: {
        main: [],
        lines_error: {},
        lines_warning: {},
        lines_info: {},
    },
    /** @type {LotColors} lotsColor */
    lotsColor: {},
    /** @type {Array<String>} staticItemsList */
    staticItemsList: {},
    /** @type {Number} fileMaxSize */
    fileMaxSize: 0,
    /** @type {PriceDisplay} priceFieldsDisplay */
    priceFieldsDisplay: {
        label: DisplayState.hidden,
        supply: DisplayState.hidden,
        drop: DisplayState.hidden,
        drop_off: DisplayState.hidden,
    },

    /** @type {Number} currentPage */
    currentPage: 1,
    /**
     * @return {Number}
     */
    get linePerLoad() {
        return configuration.estimate_edition.line_per_load;
    },

    // Permissions
    /** @type {EstimatePermissions} permissions */
    permissions: {
        mustGoToFirstProcess: false,
        canGoToFirstProcess: false,
        canEditEstimateLines: false,
        canReopenEstimate: false,
        canDeleteEstimate: false,
        canAbandonEstimate: false,
        canChangeStatus: false,
        canValidateOnCos: false,
        canCreateRemark: false,
    },

    // User inputs
    /** @type {String} estimateRemarkUserInput */
    estimateRemarkUserInput: "",
    /** @type {Array<String>} lineRemarkUserInputBag */
    lineRemarkUserInputBag: {},
    /** @type {Array<Array<String>>} remarkUserErrorBag */
    remarkUserErrorBag: {},

    // Front states
    /** @type {Boolean} gotAllLines */
    gotAllLines: false,
    /** @type {Boolean} didInitialRequest */
    didInitialRequest: false,
    /** @type {Boolean} isUpdating */
    isUpdating: false,

    /** @type {Boolean} isUpdating */
    canGotoBottom: true,

    /**
     * Initialize the component
     */
    async init() {
        await this.performInitializationRequest(this.linePerLoad);
    },

    /**
     * Perform the initial request to show lines.
     * @param {Number} [lineQuantity] The count of lines to retrieve for a page
     */
    async performInitializationRequest(linePerPage = this.linePerLoad) {
        // Reset some variables to a default value
        this.gotAllLines = false;
        this.didInitialRequest = false;
        this.isUpdating = false;
        this.estimateRemarks = [];
        this.lineRemarks = [];
        this.currentPage = 1;

        // Replace lines by dummies for waiting display
        this.lines = this.createWaitingLines(this.lineCount);

        /** @type {InitialRequestData} data */
        const data = await this.$wire.call(
            "getInitializationData",
            this.estimateVersion,
            linePerPage,
            this.currentPage,
        );

        // Set data that often change between requests
        this.replaceLinesProperly(data.lines.items, this.currentPage);
        this.updateTotalPanelValues(data.totalPanel);
        this.updateErrorBag(data.errorBag);
        this.updatePermissions(data.permissions);

        // Set data that never change
        this.lotsColor = data.lotsColor;
        this.staticItemsList = data.staticItemsList;
        this.estimateRemarks = data.estimateRemarks;
        this.lineRemarks = data.lineRemarks;
        this.fileMaxSize = data.fileMaxSize;
        this.estimate = data.estimate;

        // Tells the program we did the initial request
        this.didInitialRequest = true;

        // Trigger the load-until only if we didnt have all the lines.
        if (data.lines.hasMorePage) {
            this.loadLinesUntilLast();
        } else {
            this.gotAllLines = true;
        }
    },

    // ----------------------
    // Global public methods
    // ----------------------

    /**
     * Reload permissions, errorBag, totalPanel and maybe other things
     * @param {Number} version The estimate version
     */
    async reloadEstimateMetadata(version) {
        const data = await this.$wire.call("getFreshEstimateMetadata", version);
        this.updateTotalPanelValues(data.totalPanel);
        this.updatePermissions(data.permissions);
        this.updateErrorBag(data.errorBag);
    },

    /**
     * Retrieve a line index from an id.
     * @param {Number} lineId The id from a line to find
     * @returns {Number|null} A number with the index or null
     */
    getLineIndexFromId(lineId) {
        const index = this.lines.findIndex((line) => line.id === lineId);
        return -1 === index ? null : index;
    },

    /**
     * Retrieve a line id from an index.
     * @param {Number} lineIndex The index from a line to find
     * @returns {Number|null} A number with the index or null
     */
    getLineIdFromIndex(lineIndex) {
        return this.lines[lineIndex]?.id ?? null;
    },

    /**
     * Update the current permissions
     */
    updatePermissions(newPermissions) {
        for (const permissionName in newPermissions) {
            if (Object.hasOwnProperty.call(newPermissions, permissionName)) {
                const permissionValue = newPermissions[permissionName];
                this.permissions[permissionName] = permissionValue;
            }
        }
    },

    /**
     * Perform an action when livewire had encountered an error.
     * @param {String} customMessage A custom message to show
     */
    livewireCallHadAnError(
        customMessage = "The page has encountered an error. You can have a minor bug, please refresh the page.",
    ) {
        this.isUpdating = false;
        alert(customMessage);
    },

    // ---------------------
    // Other public methods
    // ---------------------

    /**
     * Create a display structure for price fields
     * @param {LineDto} line The line to analyse
     * @param {String} lesserValueUnit The unit value for "lesser value"
     * @returns {Object}
     */
    createPriceDisplayMapping(line) {
        const display = {
            label: DisplayState.hidden,
            supply: line.supply_available
                ? DisplayState.show
                : DisplayState.hidden,
            drop: line.drop_available ? DisplayState.show : DisplayState.hidden,
            drop_off: line.drop_off_available
                ? DisplayState.show
                : DisplayState.hidden,
        };

        if (
            line.override_price ||
            line.support_required ||
            line.unit === lesserValueUnit
        ) {
            display.label = DisplayState.show;
            display.drop = DisplayState.remove;
            display.drop_off = DisplayState.remove;
        }

        return display;
    },

    /**
     * Create an empty price display
     * @returns {Object}
     */
    createEmptyPriceDisplayMapping() {
        return {
            label: DisplayState.hidden,
            supply: DisplayState.hidden,
            drop: DisplayState.hidden,
            drop_off: DisplayState.hidden,
        };
    },

    /**
     * Update a price display
     * @param {LineDto} line The line to map
     */
    updatePriceDisplayForLine(line) {
        this.priceFieldsDisplay[line.id] = this.createPriceDisplayMapping(line);
    },

    /**
     * Retrieve a price display if exists for a line or an empty display
     * @param {Number} lineIndex The line index to find
     * @returns {PriceDisplay}
     */
    getPriceDisplayFromIndex(lineIndex) {
        const lineId = this.getLineIdFromIndex(lineIndex);
        const foundItem = this.priceFieldsDisplay[lineId];

        return undefined === foundItem
            ? this.createEmptyPriceDisplayMapping()
            : foundItem;
    },

    /**
     * Retrieve a price display from a line id
     * @param {Number} lineId The line id for the price display to get
     * @returns {PriceDisplay}
     */
    getPriceDisplayFromId(lineId) {
        const foundItem = this.priceFieldsDisplay[lineId];

        return undefined === foundItem
            ? this.createEmptyPriceDisplayMapping()
            : foundItem;
    },

    /**
     * Change the current estimate version
     * @param {Number} newVersion The estimate version to define
     * @param {Number} newLineCount The estimate dummy line number to show at line refresh
     * @returns {self}
     */
    changeVersion(newVersion, newLineCount) {
        this.lockCurrentDocument();
        this.estimateVersion = newVersion;
        if (newLineCount) {
            this.lineCount = newLineCount;
        }
        this.currentPage = 1;

        return this;
    },

    /**
     * Reload the reader component
     */
    reloadComponent() {
        this.lockCurrentDocument();
        this.currentPage = 1;
        this.performInitializationRequest();
    },

    /**
     * Lock the current document permission. Need to refresh theses information.
     */
    lockCurrentDocument() {
        for (const permissionName in this.permissions) {
            if (Object.hasOwnProperty.call(this.permissions, permissionName)) {
                this.permissions[permissionName] = false;
            }
        }
    },

    /**
     * Move a line from its old position to a new position
     * @param {Number} lineId The line id
     * @param {Number} lineOldIndex The old line position
     * @param {Number} lineNewIndex The new line position
     */
    moveLine(lineId, lineOldIndex, lineNewIndex) {
        // Reorder the dragged item in the internal list
        const lines = Alpine.raw(this.lines);
        const droppedAtItem = lines.splice(lineOldIndex, 1)[0];
        lines.splice(lineNewIndex, 0, droppedAtItem);
        this.lines = lines;

        // Reassign the order property for lines AND create the list for the backend sync
        const reordered = {};
        this.lines.forEach((curLine, iterator) => {
            if (curLine.id !== lineId) {
                curLine.order = iterator;
            } else {
                curLine.order = lineNewIndex;
            }
            reordered[curLine.id] = curLine.order;
        });

        // Sync the new ordering with the backend
        this.callSyncLineOrdering(reordered);
    },

    /**
     * Reorder the estimate lines from the beginning
     */
    reorderLinesFromBeginning(updateBackend = false) {
        const reordered = {};
        this.lines.forEach((curLine, iterator) => {
            curLine.order = iterator;
            reordered[curLine.id] = curLine.order;
        });

        if (updateBackend) {
            this.callSyncLineOrdering(reordered);
        }
    },

    /**
     * Call the backend to sync line order with given array.
     *
     * @param {Array<Number, String>} reordered
     */
    callSyncLineOrdering(reordered) {
        this.isUpdating = true;
        this.$wire
            .call("reorderLines", reordered)
            .then(() => {
                this.isUpdating = false;

                // IF NEEDED, UNCOMMENT HERE AND BACKEND SIDE: Update internal error bag
                // /** @type {ErrorBag} errorBag */
                // const errorBag = data.errorBag;
                // this.updateErrorBag(errorBag);
            })
            .catch(() => {
                this.livewireCallHadAnError();
            });
    },

    /**
     * Reload the lines property
     */
    reloadLinesProperty() {
        const lines = Alpine.raw(this.lines);
        this.$nextTick(() => {
            this.lines = lines;
        });
    },

    /**
     * Scroll the page to the bottom content
     */
    scrollToEstimateRemarkSection() {
        /** @type {HTMLElement} e */
        const e = document.getElementById("estimate-remarks");
        const rect = e.getBoundingClientRect();

        zenScroll.toY(rect.top - 280);
    },

    /**
     * Checks if the user can scroll to the bottom
     */
    checkIfCanScrollToBottom() {
        const doc = document.documentElement;
        const top = (window.scrollY || doc.scrollTop) - (doc.clientTop || 0);

        /** @type {HTMLElement} e */
        const e = document.getElementById("estimate-remarks");
        const rect = e.getBoundingClientRect();

        this.canGotoBottom = top < rect.top;
    },

    /**
     * Retrieve the content from a reactive data
     *
     * @param {Proxy} reactive
     */
    toRaw(reactive) {
        return Object.assign({}, reactive);
    },

    /**
     * Retrieve the content from a reactive data as array
     *
     * @param {Proxy} reactive
     */
    toRawArray(reactive) {
        return Object.assign([], reactive);
    },

    /**
     * Create an array of dummy lines.
     * @param {Number} quantity
     * @returns Array<Object>
     */
    createWaitingLines(quantity) {
        return Array(quantity).fill({ not_loaded: true });
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Line managment
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
    /**
     * Place lines into the lines bag.
     * @param {LineDto} lines The lines to insert
     * @param {Number} page The current page
     */
    replaceLinesProperly(lines, page) {
        lines.forEach((line, index) => {
            const linePosition = (page - 1) * this.linePerLoad + index;
            this.lines.splice(linePosition, 1, line);
            this.updatePriceDisplayForLine(line);
        });
    },

    /**
     * Retrieve the lines but paginated
     * @param {Number} lineQuantity The count of lines to retrieve for a page
     * @param {Number} page The page to retrieve
     * @returns {LineCollection}
     */
    async getPaginatedLines(lineQuantity = 10, page = 1) {
        return await this.$wire.call(
            "getPaginatedLines",
            this.estimateVersion,
            lineQuantity,
            page,
        );
    },

    /**
     * Load lines until you loaded all lines.
     */
    async loadLinesUntilLast() {
        this.currentPage++;
        const lineCollection = await this.getPaginatedLines(
            this.linePerLoad,
            this.currentPage,
        );
        this.replaceLinesProperly(lineCollection.items, this.currentPage);

        if (lineCollection.hasMorePage) {
            // Load more lines
            this.loadLinesUntilLast();
        } else {
            // Allows the user to update the lines
            this.gotAllLines = true;
        }
    },

    /**
     * Add a new line from an id
     * @param {Number} lineId A line id to add (frontend)
     */
    addLineFromId(lineId) {
        const lineIndex = this.getLineIndexFromId(lineId);

        // Do something only if the line doesnt exists
        if (null === lineIndex) {
            /** @type {LineRequest} data */
            this.$wire
                .call("getLine", lineId)
                .then((data) => {
                    const order = data.line.order;

                    this.lines.push(data.line);
                    this.updatePriceDisplayForLine(data.line);
                    this.$dispatch("update-frontend-for-line-metadata", {
                        line: data.line,
                    });

                    // reorder line
                    this.lines.forEach((currentLine) => {
                        if (
                            currentLine.order >= order &&
                            currentLine.id !== data.line.id
                        ) {
                            currentLine.order++;
                        }
                    });
                    this.lines.sort((line1, line2) =>
                        line1.order > line2.order ? 1 : -1,
                    );

                    // Update internal error bag
                    /** @type {ErrorBag} errorBag */
                    const errorBag = data.errorBag;
                    this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                    this.updatePermissions(data.permissions);

                    this.isUpdating = false;
                })
                .catch(() => {
                    this.livewireCallHadAnError();
                });
        }
    },

    /**
     * Remove an estimate line (in frontend)
     * @param {Number} lineId A line id to remove (frontend)
     */
    hardDeleteLineFromId(lineId) {
        lineId = parseInt(lineId);
        const lineIndex = this.getLineIndexFromId(lineId);
        if (null === lineIndex) {
            throw `The line with id ${lineId} cannot be found`;
        }
        this.hardDeleteLineFromIndex(lineIndex, lineId);
        this.removeErrorBagLineFromId(lineId);
    },

    /**
     * Remove an estimate line (in frontend)
     * @param {Number} lineIndex A line index to remove
     * @param {Number} lineId An optional argument to gain performance if already has line id
     */
    hardDeleteLineFromIndex(lineIndex, lineId = null) {
        // Remove the line from the array
        this.lines.splice(lineIndex, 1);

        // The following will remove the associated line remarks
        if (null === lineId) {
            // If lineId equals null even after search, that will do not trigger any error
            lineId = this.getLineIdFromIndex(lineId);
        }

        this.removeAssociatedLineRemarkWithLineId(lineId);
        this.removeErrorBagLineFromId(lineId);
        this.reorderLinesFromBeginning({ updateBackend: false });
        this.reloadEstimateMetadata(this.estimateVersion);
    },

    /**
     * Soft delete a line from an index (frontend)
     * @param {Number} lineIndex The line index to soft delete (frontend)
     * @param {Boolean} ifSoftDeleteItInNextVersion If soft deleted, delete it in next version
     */
    softDeleteLineFromIndex(lineIndex, ifSoftDeleteItInNextVersion) {
        this.lines[lineIndex].not_in_next_version = true;
        if (ifSoftDeleteItInNextVersion) {
            this.lines[lineIndex].delete_in_next_version = true;
        }
    },

    /**
     * Unsoft delete/restore a line from an index (frontend)
     * @param {Number} lineIndex The line index to soft delete (frontend)
     */
    restoreLineFromIndex(lineIndex) {
        this.lines[lineIndex].not_in_next_version = false;
        this.lines[lineIndex].delete_in_next_version = false;
    },

    /**
     * Unsoft delete/restore a line from an index (frontend)
     * @param {Number} lineId The line index to soft delete (frontend)
     */
    restoredLineFromId(lineId) {
        const lineIndex = this.getLineIndexFromId(lineId);
        this.restoreLineFromIndex(lineIndex);
        this.reloadEstimateMetadata(this.estimateVersion);
    },

    /**
     * Soft delete a line from an index (frontend and backend)
     * @param {Number} lineIndex The line index to soft delete
     */
    async callSoftDeleteLineFromIndex(lineIndex) {
        const lineId = this.lines[lineIndex].id;
        this.isUpdating = true;

        try {
            const data = await this.$wire.call("softDeleteLine", lineId, true);
            this.updateTotalPanelValues(data.totalPanel);

            // Change the line display (frontend)
            this.softDeleteLineFromIndex(lineIndex);

            // Update internal error bag
            /** @type {ErrorBag} errorBag */
            const errorBag = data.errorBag;
            this.updateErrorBagFromDataAndLineId(errorBag, lineId);

            // Tell the top component to recalculate the global price
            this.$wire.emit("calculateGlobalPrice");
            this.isUpdating = false;
        } catch (error) {
            this.livewireCallHadAnError();
        }
    },

    /**
     * Unsoft delete/restore a line from an index (frontend and backend)
     * @param {Number} lineIndex The line index to restore (in frontend and backend)
     */
    async callRestoreLineFromIndex(lineIndex) {
        const lineId = this.lines[lineIndex].id;
        this.isUpdating = true;

        try {
            const data = await this.$wire.call("softDeleteLine", lineId, false);
            this.updateTotalPanelValues(data.totalPanel);

            // Change the line display (frontend)
            this.restoreLineFromIndex(lineIndex);

            // Update internal error bag
            /** @type {ErrorBag} errorBag */
            const errorBag = data.errorBag;
            this.updateErrorBagFromDataAndLineId(errorBag, lineId);
            // Tell the top component to recalculate the global price
            this.$wire.emit("calculateGlobalPrice");
            this.isUpdating = false;
        } catch (error) {
            this.livewireCallHadAnError();
        }
    },

    /**
     * Trigger the openning of the line deletion modal
     * @param {Number} lineIndex The line index you wanna delete
     */
    openLineDeletionModal(lineIndex) {
        this.isUpdating = true;
        this.$wire
            .call("setLineToDeleteIt", this.lines[lineIndex].id)
            .then(() => {
                this.isUpdating = false;
            })
            .catch(() => {
                this.livewireCallHadAnError();
            });
    },

    /**
     * Hard delete, soft delete or restore a line in backend and frontend from a line index.
     * @param {Number} lineIndex The line index in the list (frontend and backend)
     */
    async callSoftOrHardDeleteOrRestoreLineFromIndex(lineIndex) {
        // If the line is not new from this version, we could soft delete it
        if (!this.lines[lineIndex].add_in_this_version) {
            // We can know if a line should be soft deleted or restore from the not_in_next_version property.
            const shouldSoftDelete = !this.lines[lineIndex].not_in_next_version;

            if (shouldSoftDelete) {
                await this.callSoftDeleteLineFromIndex(lineIndex);
            } else {
                await this.callRestoreLineFromIndex(lineIndex);
            }
            // We should trigger the SelectSlipItems livewire component and tells him to reload its collection
            this.$wire.emit("SelectSlipItems", "updateSlipList");
        } else {
            // Otherwise, we should delete it
            this.openLineDeletionModal(lineIndex);
        }
    },

    /**
     * Soft delete/delete a line in frontend.
     * @param {Number} lineId The line id to delete/soft delete (frontend)
     * @param {Boolean} shouldSoftDelete Tells if should hard of soft delete a line
     * @param {Boolean} ifSoftDeleteItInNextVersion If soft deleted, delete it in next version
     */
    softOrHardDeleteLineFromId(
        lineId,
        shouldSoftDelete,
        ifSoftDeleteItInNextVersion,
    ) {
        const lineIndex = this.getLineIndexFromId(lineId);

        if (null !== lineIndex) {
            if (shouldSoftDelete) {
                this.softDeleteLineFromIndex(
                    lineIndex,
                    ifSoftDeleteItInNextVersion,
                );
            } else {
                this.hardDeleteLineFromIndex(lineIndex);
            }
            this.reloadEstimateMetadata(this.estimateVersion);
        }
    },

    /**
     * Describe what happen when line is updated frontside
     * @param {Number} lineIndex
     */
    markLineAsUpdatedFromIndex(lineIndex) {
        this.lines[lineIndex].has_new_or_dirty_line_or_remark = true;
    },

    /**
     * Toggle the action to override a line price from a line index.
     */
    callToggleOverridePriceFromIndex(lineIndex) {
        const lineId = this.getLineIdFromIndex(lineIndex);

        this.isUpdating = true;
        this.$wire
            .call("toggleOverridePrice", lineId)
            .then((data) => {
                this.isUpdating = false;

                // Update the line
                this.lines[lineIndex] = data.updatedLine;
                this.updatePriceDisplayForLine(data.updatedLine);
                this.$dispatch("update-frontend-for-line-metadata", {
                    line: data.updatedLine,
                });

                // Update the total
                this.updateTotalPanelValues(data.totalPanel);

                // Update internal error bag
                /** @type {ErrorBag} errorBag */
                const errorBag = data.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(data.permissions);
            })
            .catch(() => {
                this.livewireCallHadAnError();
            });
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Line remark managment
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Add a line remark to the lineRemarks container
     * @param {Number} lineId The line id to update
     * @param {LineRemark} remark The remark message to update
     */
    addLineRemark(lineId, remark) {
        // Fix bug where the line doesnt exists
        if (this.lineRemarks[lineId] === undefined) {
            this.lineRemarks[lineId] = [];
        }
        this.lineRemarks[lineId].push(remark);

        const lineIndex = this.getLineIndexFromId(lineId);
        this.lines[lineIndex].has_new_or_dirty_line_or_remark = true;
    },

    /**
     * Remove a line remark from an id (frontend and backend)
     * @param {Number} lineRemarkId The line remark id to remove
     */
    callRemoveLineRemarkFromId(lineRemarkId, lineId) {
        this.isUpdating = true;

        this.removeLineRemarkFromId(lineRemarkId, lineId);
        this.$wire
            .call("deleteLineRemarkFromId", lineRemarkId, lineId)
            .then((data) => {
                // Update internal error bag
                /** @type {ErrorBag} errorBag */
                const errorBag = data.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(data.permissions);

                this.isUpdating = false;
            })
            .catch(() => {
                this.livewireCallHadAnError();
            });
    },

    /**
     * Remove the line remark from the frontend bag (frontend)
     * @param {Number} lineRemarkId The id of the line remark to remove
     * @param {Number} lineId The line id to find where the remark is stored
     */
    removeLineRemarkFromId(lineRemarkId, lineId) {
        const remarkIndexInBag = this.lineRemarks[lineId].findIndex(
            (lineRemark) => lineRemark.id === lineRemarkId,
        );
        this.lineRemarks[lineId].splice(remarkIndexInBag, 1);
    },

    /**
     * Remove all the associated line remarks with a given line id
     * @param {Number} lineId The line id associated with all the line remarks
     */
    removeAssociatedLineRemarkWithLineId(lineId) {
        delete this.lineRemarks[lineId];
    },

    /**
     * Retrieve line remarks conditionnaly
     * @param {Boolean} showAll Choose to show all lines remarks
     * @param {Number} lineRemarkLimitedQuantity The quantity of line remarks to show
     */
    getLineRemarks(lineId, showAll, lineRemarkLimitedQuantity) {
        return 0 < this.lineRemarks[lineId]?.length
            ? showAll
                ? this.lineRemarks[lineId]
                : this.lineRemarks[lineId]?.slice(0, lineRemarkLimitedQuantity)
            : [];
    },

    /**
     * Update our buffer memory for line remarks so after we can send it to the backend.
     * @param {Number} lineId The line id to update (in backend)
     */
    updateLineRemarkBufferMemory(lineId) {
        const value = this.$el.value;

        // Manage input error
        const errorMessages = [];

        if ("" === value) {
            errorMessages.push("Your remark cannot be empty");
        }

        if (value.length > lineRemarkMaxInput) {
            errorMessages.push(
                `Your remark cannot be higher than ${lineRemarkMaxInput} characters`,
            );
        }

        // Force to remove any error previously displayed
        if (0 === errorMessages.count) {
            this.removeLineRemarkError(lineId);
        }

        // If there was any error message
        if (0 !== errorMessages.count) {
            this.remarkUserErrorBag[lineId] = [];
            errorMessages.forEach((message) => {
                this.remarkUserErrorBag[lineId].push(message);
            });
        }

        this.lineRemarkUserInputBag[lineId] = value;
    },

    /**
     * Ask the backend to add a line remark from our buffer memory.
     * @param {Number} lineId The line id to update
     */
    callAddLineRemarkFromBufferMemory(lineId) {
        const value =
            this.lineRemarkUserInputBag[lineId] !== undefined
                ? this.lineRemarkUserInputBag[lineId]
                : "";

        // We only add remark when the field is not empty
        if (!this.hasAnyLineRemarkError(lineId)) {
            delete this.lineRemarkUserInputBag[lineId];
            this.$el.value = "";

            this.isUpdating = true;
            this.$wire
                .call("addLineRemark", lineId, value)
                .then((data) => {
                    if (data.newRemark) {
                        this.addLineRemark(lineId, data.newRemark);
                    }
                    if (data.errorBag) {
                        // Update internal error bag
                        /** @type {ErrorBag} errorBag */
                        const errorBag = data.errorBag;
                        this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                    }
                    if (data.permissions) {
                        this.updatePermissions(data.permissions);
                    }

                    this.isUpdating = false;
                })
                .catch(() => {
                    this.livewireCallHadAnError();
                });
        }
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Document upload
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Show a document from its id
     * @param {Number} documentId The document id to open
     */
    openLineDocument(documentId) {
        this.$wire
            .call("getRouteToShowDocument", documentId)
            .then((data) => {
                window.open(data.url, "_blank");
            })
            .catch(() => {
                this.livewireCallHadAnError();
            });
    },

    /**
     * Triggered when a file has been updated
     * @param {Number} lineId The number id that has a file
     * @param {String} filename The document name that was affected to a line
     * @param {Number} fileId The id of the attached document
     * @param {ErrorBag} errorBag The error bag retrieved after the file has been attached
     */
    fileHasBeenAttached(lineId, filename, documentId, errorBag) {
        const lineIndex = this.getLineIndexFromId(lineId);
        if (null !== lineIndex) {
            this.lines[lineIndex].document_id = documentId;
            this.lines[lineIndex].document_name = filename;
        }
        this.updateErrorBagFromDataAndLineId(errorBag, lineId);
    },

    /**
     * Call a modal that ask to dissociate or not a document from a line.
     * @param {Number} documentId The id of the document to remove
     * @param {Number} lineId The line impacted
     */
    callDissociateDocumentFromId(documentId, lineId) {
        this.isUpdating = true;
        this.$wire.call("setDeleteFileId", documentId, lineId).then(() => {
            this.isUpdating = false;
        });
    },

    /**
     * Triggered when a document attached to a file has been removed
     * @param {Number} lineId The id of the line that has its file deleted
     * @param {String} successMessage A success message to show
     * @param {ErrorBag} errorBag The error bag retrieved after the file has been attached
     */
    fileHasBeenDissociated(lineId, successMessage, errorBag) {
        const lineIndex = this.getLineIndexFromId(lineId);
        if (null !== lineIndex) {
            this.$dispatch("banner-message", {
                style: BannerStatus.Success,
                message: successMessage,
            });
            this.lines[lineIndex].document_id = null;
            this.lines[lineIndex].document_name = null;
        }
        this.updateErrorBagFromDataAndLineId(errorBag, lineId);
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Error bag managment
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Perform a request to refresh the total panel data.
     */
    async reloadErrorBag() {
        const errorBag = await this.$wire.call(
            "getEstimateErrorContent",
            this.estimateVersion,
        );
        this.updateErrorBag(errorBag);
    },

    /**
     * Checks if a line error exists for a line from its id
     * @param {Number} lineId The line id to retrieve any error
     * @return {Boolean}
     */
    hasLineErrorFromLineId(lineId) {
        return this.errorBag.lines_error[lineId] !== undefined;
    },

    /**
     * Checks if a compatibility error exists for a line from its id
     * @param {Number} lineId The line id to retrieve any error
     * @return {Boolean}
     */
    hasLineWarningFromLineId(lineId) {
        return this.errorBag.lines_warning[lineId] !== undefined;
    },

    /**
     * Checks if a compatibility error exists for a line from its id
     * @param {Number} lineId The line id to retrieve any error
     * @return {Boolean}
     */
    hasLineInfoFromLineId(lineId) {
        return this.errorBag.lines_info[lineId] !== undefined;
    },

    /**
     * Checks if any kind of error exists for a line from its id
     * @param {Number} lineId The line id to retrieve any error
     * @return {Boolean}
     */
    hasAnyLineNoticeFromLineId(lineId) {
        return (
            this.hasLineErrorFromLineId(lineId) ||
            this.hasLineWarningFromLineId(lineId) ||
            this.hasLineInfoFromLineId(lineId)
        );
    },

    /**
     * Checks if there is any error in the error bag.
     * @returns {Boolean}
     */
    hasAnyNotice() {
        return (
            0 !== this.errorBag.main.length ||
            0 !== Object.keys(this.errorBag.lines_error).length ||
            0 !== Object.keys(this.errorBag.lines_warning).length ||
            0 !== Object.keys(this.errorBag.lines_info).length
        );
    },

    /**
     * Checks if there is any error in the error bag.
     * @param {Number} lineId The line id to retrieve any error
     * @returns {Boolean}
     */
    hasAnyLineNotice(lineId) {
        return (
            0 !==
            (this.getLineErrorObjectForLine(lineId)?.errors?.length ?? 0) ||
            0 !==
            (this.getLineWarningObjectForLine(lineId)?.errors?.length ??
                0) ||
            0 !== (this.getLineInfoObjectForLine(lineId)?.errors?.length ?? 0)
        );
    },

    /**
     * Checks if there is any blocking error in the error bag.
     * @returns {Boolean}
     */
    hasAnyBlockingError() {
        return (
            0 !== this.errorBag.main.length ||
            0 !== Object.keys(this.errorBag.lines_error).length
        );
    },

    /**
     * Retrieve any kind of error for a line from its id
     * @param {Number} lineId The line id to retrieve any error
     * @return {String}
     */
    getNoticesFromLineId(lineId) {
        let messages = [];

        if (this.hasLineErrorFromLineId(lineId)) {
            messages = messages.concat(
                this.getLineErrorObjectForLine(lineId)?.errors,
            );
        }
        if (this.hasLineWarningFromLineId(lineId)) {
            messages = messages.concat(
                this.getLineWarningObjectForLine(lineId)?.errors,
            );
        }
        if (this.hasLineInfoFromLineId(lineId)) {
            messages = messages.concat(
                this.getLineInfoObjectForLine(lineId)?.errors,
            );
        }

        return messages;
    },

    /**
     * Retrieve the line error object
     * @param {Number} lineId The line id to retrieve any error
     * @returns {LineErrorItem}
     */
    getLineErrorObjectForLine(lineId) {
        return this.errorBag.lines_error[lineId];
    },

    /**
     * Retrieve the line warning object
     * @param {Number} lineId The line id to retrieve any error
     * @returns {LineErrorItem}
     */
    getLineWarningObjectForLine(lineId) {
        return this.errorBag.lines_warning[lineId];
    },

    /**
     * Retrieve the line info object
     * @param {Number} lineId The line id to retrieve any error
     * @returns {LineErrorItem}
     */
    getLineInfoObjectForLine(lineId) {
        return this.errorBag.lines_info[lineId];
    },

    /**
     * Retrieve the logo class (for the warn sign) for a line (with id)
     * @param {Number} lineId The line id to retrieve any error
     * @returns {String}
     */
    getExclamationTriangleLogoClassFromLineId(lineId) {
        switch (this.getMostImportantNoticeGroupFromLineId(lineId)) {
            case "error":
                return "text-error-logo";
            case "warning":
                return "text-warning-logo";
            case "info":
                return "text-info-logo";
        }
        return "";
    },

    /**
     * Retrieve the most important notice group for a line
     * @param {Number} lineId The line id to retrieve any error
     * @return {String}
     */
    getMostImportantNoticeGroupFromLineId(lineId) {
        return this.hasLineErrorFromLineId(lineId)
            ? "error"
            : this.hasLineWarningFromLineId(lineId)
                ? "warning"
                : this.hasLineInfoFromLineId(lineId)
                    ? "info"
                    : "";
    },

    /**
     * Update the core error bag
     */
    updateErrorBag(errorBag) {
        this.errorBag.main = errorBag.main;
        this.errorBag.lines_error = { ...errorBag.lines_error };
        this.errorBag.lines_warning = { ...errorBag.lines_warning };
        this.errorBag.lines_info = { ...errorBag.lines_info };
    },

    /**
     * Update the internal error bag from data.
     * @param {ErrorBag} errorBag A requested error bag
     * @param {Number} lineId The line id that should have its error bag updated
     */
    updateErrorBagFromDataAndLineId(errorBag, lineId) {
        this.errorBag.main = errorBag.main;

        if (undefined !== errorBag.lines_error[lineId]) {
            this.errorBag.lines_error[lineId] = {
                ...errorBag.lines_error[lineId],
            };
        } else {
            delete this.errorBag.lines_error[lineId];
        }
        if (undefined !== errorBag.lines_warning[lineId]) {
            this.errorBag.lines_warning[lineId] = {
                ...errorBag.lines_warning[lineId],
            };
        } else {
            delete this.errorBag.lines_warning[lineId];
        }
        if (undefined !== errorBag.lines_info[lineId]) {
            this.errorBag.lines_info[lineId] = {
                ...errorBag.lines_info[lineId],
            };
        } else {
            delete this.errorBag.lines_info[lineId];
        }
    },

    /**
     * Remove a line in the error bag from a line id
     * @param {Number} lineId The line id to remove
     */
    removeErrorBagLineFromId(lineId) {
        delete this.errorBag.lines_error[lineId];
        delete this.errorBag.lines_warning[lineId];
        delete this.errorBag.lines_info[lineId];
    },

    /**
     * Checks if there is any error for a line remark input
     * @param {Number} lineId The line remark id in the error bag
     * @returns {Boolean}
     */
    hasAnyLineRemarkError(lineId) {
        return (
            lineId in this.remarkUserErrorBag &&
            0 !== this.remarkUserErrorBag[lineId].length
        );
    },

    /**
     * Retrieve any error for a line remark input
     * @param {Number} lineId The line remark id in the error bag
     * @returns {Boolean}
     */
    getLineRemarkErrors(lineId) {
        return this.remarkUserErrorBag[lineId].join(", ");
    },

    /**
     * Remove a line remark error.
     * @param {Number} lineId Line id
     */
    removeLineRemarkError(lineId) {
        delete this.remarkUserErrorBag[lineId];
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Estimate remarks
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Call the livewire method to add a new estimate remark then add this new remark to our bag.
     */
    callAddEstimateRemark() {
        if ("" !== this.estimateRemarkUserInput) {
            this.isUpdating = true;
            this.$wire
                .call("addEstimateRemark", this.estimateRemarkUserInput)
                .then((data) => {
                    // Reset the input field
                    this.estimateRemarkUserInput = "";

                    this.estimateRemarks.push(data.newRemark);

                    this.updateErrorBag(data.errorBag);
                    this.updatePermissions(data.permissions);

                    this.isUpdating = false;
                });
        }
    },

    /**
     * Remove a line remark from an id
     * @param {Number} remarkId The remark id to remove
     */
    callRemoveEstimateRemarkFromId(remarkId) {
        this.isUpdating = true;
        this.$wire.call("deleteEstimateRemark", remarkId).then((data) => {
            const index = this.getEstimateRemarkIndexFromId(remarkId);
            if (null !== index) {
                this.estimateRemarks.splice(index, 1);
            }

            this.updateErrorBag(data.errorBag);
            this.updatePermissions(data.permissions);

            this.isUpdating = false;
        });
    },

    /**
     * Private methods
     */

    /**
     * Retrieve an estimate remark index from an id.
     * @param {Number} lineId The id from a line to find
     * @returns {Number|null} A number with the index or null
     */
    getEstimateRemarkIndexFromId(remarkId) {
        const index = this.estimateRemarks.findIndex(
            (remark) => remark.id === remarkId,
        );
        return -1 === index ? null : index;
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * canUpdateMainFields
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Update a line code line
     * @param {Number} lineId The line id to update (in backend)
     * @param {Number} lineIndex The line index to update (in frontend)
     */
    updateCodeLine(lineId, lineIndex) {
        const value = this.$el.value;

        // Update internal bag
        this.lines[lineIndex].code_line = value;

        this.isUpdating = true;
        this.$wire
            .call("updateCodeLine", lineId, value)
            .then((data) => {
                this.markLineAsUpdatedFromIndex(lineIndex);

                // Update internal error bag
                /** @type {ErrorBag} errorBag */
                const errorBag = data.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(data.permissions);

                // Update the current line
                this.lines[lineIndex] = data.line;

                this.isUpdating = false;
            })
            .catch(() => {
                this.livewireCallHadAnError();
            });
    },

    /**
     * Update a line designation
     * @param {Number} lineId The line id to update (in backend)
     * @param {Number} lineIndex The line index to update (in frontend)
     */
    updateDesignation(lineId, lineIndex) {
        const value = this.$el.value;

        // Update internal bag
        this.lines[lineIndex].designation = value;

        this.isUpdating = true;
        this.$wire
            .call("updateDesignation", lineId, value)
            .then((data) => {
                this.markLineAsUpdatedFromIndex(lineIndex);

                // Update internal error bag
                /** @type {ErrorBag} errorBag */
                const errorBag = data.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(data.permissions);

                this.isUpdating = false;
            })
            .catch(() => {
                this.livewireCallHadAnError();
            });
    },

    /**
     * Update a line price for any kind of price (works from an input element)
     * @param {String} field Name of the updated field
     * @param {Number} lineId The line id to update (in backend)
     * @param {Number} lineIndex The line index to update (in frontend)
     */
    updatePriceField(field, lineId, lineIndex) {
        const authorizedFields = ["supply", "drop", "drop_off"];
        if (!authorizedFields.includes(field)) {
            throw "You cannot update a field that doesnt exists.";
        }

        // Retrieve input current value
        let value = this.$el.value;
        value = "" === value ? "0" : Numerify.forceEnglishFloatNotation(value);

        if (Numerify.isValidFloat(value)) {
            // Parse after all the checks
            const parsedValue = parseFloat(value);

            // Update internal price field
            this.lines[lineIndex][field] = value;

            this.isUpdating = true;
            this.$wire
                .call("updatePrice", field, lineId, parsedValue)
                .then((data) => {
                    this.markLineAsUpdatedFromIndex(lineIndex);

                    this.updateTotalPanelValues(data.totalPanel);
                    // Register the generated remark
                    this.addLineRemark(lineId, data.newRemark);
                    // Update formated version of the line total price column
                    this.lines[lineIndex].formatted_total = data.formattedTotal;

                    // Update internal error bag
                    /** @type {ErrorBag} errorBag */
                    const errorBag = data.errorBag;
                    this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                    this.updatePermissions(data.permissions);

                    this.isUpdating = false;
                    this.$wire.emit("calculateGlobalPrice");
                    this.$wire.emit("refreshEstimate");
                })
                .catch(() => {
                    this.livewireCallHadAnError();
                });
        } else {
            // Replace new invalid value by old valid value
            this.$el.value = this.lines[lineIndex][field];
        }
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Total panel managment
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Perform a request to refresh the total panel data.
     */
    async reloadToTalPannel() {
        const values = await this.$wire.call(
            "getCalculatedTotalPanelData",
            this.estimateVersion,
        );
        this.updateTotalPanelValues(values);
    },

    /**
     * Update the total panel property.
     * @param {any} values
     */
    updateTotalPanelValues(values) {
        this.totalPanel = values;
    },
});
