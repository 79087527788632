// Import framework
import Alpine from 'alpinejs';
// Import components
import selectMultiple from '../components/alpinejs/selectMultiple';
import selectUnique from '../components/alpinejs/selectUnique';
import estimateExporter from '../components/alpinejs/estimateExporter';
import estimateLineReader from '../components/alpinejs/estimateLineReader';
import estimateLineDocumentExporter from '../components/alpinejs/estimateLineDocumentExporter';
import secondCounter from '../components/alpinejs/secondCounter';
import submitToCos from '../components/alpinejs/submitToCos';
import teamPermissionToggle from '../components/alpinejs/teamPermissionToggle';
import scrollToMagic from '../magics/alpinejs/scrollTo';
// Import plugins
import focus from '@alpinejs/focus';

// Publish Alpine to the browser
window.Alpine = Alpine;

// Load components and plugins
Alpine.data('selectMultiple', selectMultiple);
Alpine.data('selectUnique', selectUnique);
Alpine.data('estimateExporter', estimateExporter);
Alpine.data('estimateLineReader', estimateLineReader);
Alpine.data('estimateLineDocumentExporter', estimateLineDocumentExporter);
Alpine.data('secondCounter', secondCounter);
Alpine.data('submitToCos', submitToCos);
Alpine.data('teamPermissionToggle', teamPermissionToggle);
Alpine.magic('scrollTo', scrollToMagic);
Alpine.plugin(focus);

// Final step - Do not publish Alpine to window from now
Alpine.start();
