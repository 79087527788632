import LivewireResponse from '../../services/LivewireResponse';
import { ExportStatus } from '../../enums';

const exportSessionKeyInStorage = 'exportData';

export default (documentText = 'Document', messageStepOne = "Step 1", messageStepTwo = 'Step 2', messageStepThree = 'Step 3') => ({
    exportStatus: ExportStatus.WaitingUserAction,
    exportMessage: messageStepOne,
    exportProgressionMessage: '',
    exportFilename: '',
    isRequestingExport: false,
    percentage: 0,

    /**
     * Initialize the component
     */
    async init() {

        // if (this.didAnExportAlreadyStarted()) {
        //     // Try to retrieve the filename of a previous runned export
        //     const exportFilename = this.getStoredExportData()?.filename;
        //     if (undefined === exportFilename || null === exportFilename || '' === exportFilename) {
        //         this.cleanExportStorage();
        //         throw 'The export filename was not found in the local storage. Perhaps the storage is corrupted, it will be reset.';
        //     } else {
        //         // Change the status to show the waiting display
        //         this.exportStatus = ExportStatus.GeneratingFile;
        //         this.exportMessage = messageStepTwo;

        //         // Set the filename property of the livewire component (this affect the openned link when the file is fully generated)
        //         await this.$wire.set('exportFilename', exportFilename);
        //         this.exportFilename = exportFilename;
        //         this.checkExportStatus();
        //     }
        // }
    },

    /**
     * Trigger export to excel with custom filters registered in the current livewire component.
     * If any excel sheet check fails, the livewire component/view will manage that.
     */
    exportToExcel() {
        this.isRequestingExport = true;
        this.exportMessage = messageStepOne;
        this.exportProgressionMessage = '';

        this.$wire.call('exportContentToExcel')
            .then(livewireResponse => {
                this.isRequestingExport = false;
                const res = new LivewireResponse(livewireResponse);

                if (res.isOk()) {
                    // Register the export filename both in the component and in the local storage
                    this.exportFilename = res.getBody()?.filename;
                    this.storeExportData({
                        filename: this.exportFilename,
                    });

                    // Change the status to show the waiting display
                    this.exportStatus = ExportStatus.Done;
                    // Change the message after a few time to let the user read messages
                    setTimeout(() => { this.exportMessage = messageStepTwo; }, 500);
                    // Check the export state only one second after
                    // setTimeout(() => { this.checkExportStatus(); }, 1000);
                } else {
                    // For debugging
                    // this.exportStatus = ExportStatus.GotAnError;
                }

            });
    },

    /**
     * Check any 1 second the export is finished.
     */
    async checkExportStatus() {
        const livewireResponse = await this.$wire.call('checkExportStatus', this.exportFilename);
        const res = new LivewireResponse(livewireResponse);

        if (res.isOk()) {
            const body = res.getBody();

            // If the export treatment has begun, we update the user message to show treatment advancement
            if (body.treatment_began) {
                this.exportMessage = messageStepThree;
                this.exportProgressionMessage = `${documentText} ${body.sheet_current_index}/${body.sheet_total_quantity} : ${body.sheet_current_name} ( ${body.percentage} %)`;
                this.percentage = body.percentage;
            }

            if (body.should_ask_later) {
                // Rerun the export checking method in a few seconds
                setTimeout(() => {
                    this.checkExportStatus();
                }, 2000);
            } else {
                // Set the export status to DONE then download the file automatically
                this.exportStatus = ExportStatus.Done;
                const el = document.getElementById('excel-export-url');
                el.click();
                // Remove the export data from the local storage
                this.cleanExportStorage();
            }
        } else if (400 === res.getStatus()) {
            this.exportStatus = ExportStatus.GotAnError;
            this.cleanExportStorage();
        }
    },

    /**
     * Checks the export has already started previously
     * @returns {Boolean}
     */
    didAnExportAlreadyStarted() {
        return null !== window.localStorage.getItem(exportSessionKeyInStorage);
    },

    /**
     * Retrieve data from the previsouly started export
     * @returns {Object}
     */
    getStoredExportData() {
        return JSON.parse(window.localStorage.getItem(exportSessionKeyInStorage));
    },

    /**
     * Store data about an export
     * @param {any} data Export data
     */
    storeExportData(data) {
        window.localStorage.setItem(exportSessionKeyInStorage, JSON.stringify(data));
    },

    /**
     * Clean any data about export that previously started
     */
    cleanExportStorage() {
        window.localStorage.removeItem(exportSessionKeyInStorage);
    },

    /**
     * Tells the frontend to stop following the current export
     */
    stopFollowingExport() {
        this.exportStatus = ExportStatus.WaitingUserAction;
        this.exportFilename = '';
        this.exportMessage = messageStepOne;
        this.cleanExportStorage();
    },
});
