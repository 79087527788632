import { Permission } from "../../enums";
/**
 * @typedef {import("../../interfaces/Permission").UserWithpermissions} UserWithpermissions
 * @param {Array<UserWithpermissions>} users
 * @param {Number} initialLineCount
 * @returns {Object}
 */
export default (livewireRetrieveData, livewireUpdateData = 'updateTeamPermission') => ({
    /** @var {Array<UserWithpermissions>} users */
    users: [],

    /** @var {Boolean} booted */
    booted: false,

    /** @var {Boolean} loading */
    loading: false,

    /** @var {Number} backendCallCount Support for multiple requesting at the same time */
    backendCallCount: 0,

    /**
     * Initialize data.
     */
    init() {
        this.$wire.call(livewireRetrieveData)
            .then(users => {
                this.users = users;
                this.booted = true;
            });
    },

    /**
     * Update the team permissions.
     * @param {Number} userId
     * @param {Permission} permissionName
     */
    updateTeamPermission(userId, permissionName) {
        if (!Permission.exists(permissionName)) {
            throw `The permission ${permissionName} is not supported`;
        }

        // You cannot read without write
        if (Permission.write === permissionName) {
            const oldValue = this.users[userId].can_edit;
            if (false === oldValue) {
                this.users[userId].can_view = true;
            }
            this.users[userId].can_edit = !oldValue;
        }
        // You cannot unread and keeping write
        if (Permission.read === permissionName) {
            const oldValue = this.users[userId].can_view;
            if (true === oldValue) {
                this.users[userId].can_edit = false;
            }
            this.users[userId].can_view = !oldValue;
        }

        // Support loading while calling the backend
        this.loading = true;
        this.backendCallCount++;
        // Update backend
        this.$wire.call(livewireUpdateData, userId, permissionName)
            .then(() => {
                this.backendCallCount--;
                if (this.backendCallCount <= 0) {
                    this.loading = false;
                }
            });
    },

    /**
     * Refresh the permissions
     */
    refreshPermissions() {
        this.$wire.call(livewireRetrieveData)
            .then(users => this.users = users);
    },
});
