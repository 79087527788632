export default {
    /**
     * Checks if a value is a valid float
     * @param {String} value
     * @returns String
     */
    isValidFloat(value) {
        return !isNaN(value);
    },

    /**
     * Ensure a value use the float "." notation
     * @param {String} value
     * @returns String
     */
    forceEnglishFloatNotation(value) {
        return value.replace(",", ".");
    },
};
