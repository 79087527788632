import { BannerStatus } from '../../enums';

/**
 * @typedef {import('../../interfaces/ReaderRequests').ErrorBag} ErrorBag
 */
export default (lineId) => ({
    isUploading: false,
    hasSuccessfullyUploaded: false,
    hasError: false,
    progress: 0,
    file: null,
    lineIdToAttachDocument: lineId,

    /**
     * Initialize the component
     */
    init() {
        this.$watch('file', (value) => {
            this.submitFileToLivewire(value);
        });
    },

    /**
     * Submit a file to a livewire component
     * @param {any} file A file to submit
     */
    submitFileToLivewire() {
        this.hasSuccessfullyUploaded = false;
        this.hasError = false;
        this.isUploading = true;
        this.progress = 0;

        this.$wire.upload(
            'file',
            this.file,
            () => this.finishCallback(this),
            () => this.errorCallback(this),
            (event) => this.progressCallback(event, this),
        );
    },

    /**
     * What happen after our upload is finished
     * @param {String} uploadedFilename The filename of an uploaded file
     */
    finishCallback(self) {
        self.isUploading = false;
        self.hasSuccessfullyUploaded = true;
        // Tells the estimate line reader component to be in update state
        self.$dispatch('is-updating');

        // Tell livewire to register the file
        self.$wire.call('addFileToLine', self.lineIdToAttachDocument)
            .then((data) => {
                /** @type {ErrorBag} errorBag */
                const errorBag = data.errorBag;

                self.$dispatch('banner-message', {
                    style: BannerStatus.Success,
                    message: data.successMessage,
                });
                self.$dispatch('file-attached', {
                    lineId: self.lineIdToAttachDocument,
                    filename: data.documentName,
                    documentId: data.documentId,
                    errorBag: errorBag,
                });
                // Tells the estimate line reader component to be in update state
                self.$dispatch('is-not-updating-anymore');

                self.hasSuccessfullyUploaded = false;

            })
            .catch(() => {
                self.hasError = true;
                self.$dispatch('banner-message', {
                    style: BannerStatus.Error,
                    message: 'Cannot add line to file',
                });
            });
    },

    /**
     * What happen when file submitting encounter any error
     */
    errorCallback(self) {
        self.isUploading = false;
        self.hasError = true;
    },

    /**
     * What happen when progressing while uploading
     * @param {CustomEvent} event The progress event
     */
    progressCallback(event, self) {
        self.progress = event.detail.progress;
    },
});
