export const ExportStatus = {
    WaitingUserAction: "waiting-user-action",
    GeneratingFile: "generating-file",
    GotAnError: "got-an-error",
    Done: "done",
};

export const BannerStatus = {
    Success: 'success',
    Error: 'danger',
};

export const DisplayState = {
    show: 'show',
    hidden: 'hidden',
    remove: 'remove',
};

export const Permission = {
    read: 'read',
    write: 'write',
    /**
     *
     * @param {String} permission
     * @returns {Boolean}
     */
    exists(permission) {
        return [this.read, this.write].includes(permission);
    },
};
