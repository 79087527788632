export default class {
    /**
     * Create the livewire response
     * @param {any} response An ajax response
     */
    constructor(response) {
        this.status = response.status;
        this.body = response.body;
    }

    /**
     * Retrieve the response status
     * @returns {Number}
     */
    getStatus() {
        return this.status;
    }

    /**
     * Retrieve the response body
     * @returns {Object}
     */
    getBody() {
        return this.body;
    }

    /**
     * Checks the response status is ok
     * @returns {Boolean}
     */
    isOk() {
        /* eslint-disable-next-line yoda */
        return this.status >= 200 && this.status < 300;
    }

    /**
     * Checks the response status is not ok
     * @returns {Boolean}
     */
    isServerError() {
        /* eslint-disable-next-line yoda */
        return this.status >= 500 && this.status < 600;
    }
}
