export default () => ({
    /** @var {Boolean} confirmCreatePo */
    confirmCreatePo: false,

    /** @var {Boolean} hasAllNeeded */
    hasAllNeeded: false,

    /** @var {Boolean} estimateSubmitted */
    estimateSubmitted: false,

    /** @var {Boolean} alreadySubmitted */
    alreadySubmitted: false,

    /** @var {Boolean} isUpdating */
    isUpdating: false,

    /** @var {String} errorMessage */
    errorMessage: "",

    /**
     * The user would like to submit a Po
     */
    wouldLikeToSubmitPo() {
        // Reset the error message
        this.errorMessage = '';

        // The button toggle the display
        this.confirmCreatePo = !this.confirmCreatePo;

        // We only perform request on openning
        if (this.confirmCreatePo) {
            this.checkCanCreatePo();
        }
    },

    /**
     * Checks if we can create a Po
     */
    checkCanCreatePo() {
        // Update display to show a loading
        this.isUpdating = true;

        this.$wire.call('canSubmitOnCos')
            .then((checkRes) => {
                // We checks every needed data are okay
                if (checkRes.hasAllNeeded) {
                    // Update display to show everything is okay
                    this.hasAllNeeded = true;

                    // We check the estimate has not been already submitted
                    if (checkRes.alreadySubmitted) {
                        // Update display to show estimate has been already submitted
                        this.estimateSubmitted = true;
                        this.alreadySubmitted = true;
                    } else {
                        // We can submit it
                        this.alreadySubmitted = false;
                        this.submitEstimateOnCos();
                    }
                } else {
                    this.hasAllNeeded = false;
                }
                // After all treatments, we update the whole display
                this.isUpdating = false;
            })
            .catch(this.displayError);
    },

    /**
     * Submit an estimate on COS
     */
    submitEstimateOnCos() {
        this.$wire.call('submitEstimateOnCos')
            .then((submitRes) => {
                // Can either reset or set a new message
                this.errorMessage = submitRes.errorMessage;

                if (submitRes.done) {
                    this.estimateSubmitted = true;
                }
            })
            .catch(this.displayError);
    },

    /**
     * Display an error to the user
     *
     * @param {Error} error
     * @param {String|null} message
     */
    displayError(error, message = null) {
        message ??= 'Something goes wrong. Please contact an administrator';
        alert(message);
        // eslint-disable-next-line no-console
        console.log(error);
    },
});
